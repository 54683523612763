<template>
  <div>
    <div
      class="uk-container uk-margin-top uk-margin-medium-bottom"
      v-if="!loading"
    >
      <div class="row">
        <div class="col-md-12">
          <a
            href="#"
            @click.prevent="update"
            class="btn btn-success float-right"
            ><i class="lni lni-save mr-2"></i>Save</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Page Title</label>
            <input
              type="text"
              v-model="content.intro.title"
              placeholder="title"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Page Intro</label>
            <textarea
              placeholder="Content"
              rows="5"
              class="form-control"
              v-model="content.intro.content"
            ></textarea>
          </div>
        </div>
      </div>
      <a
        href="#"
        @click.prevent="content.brokers.push({ features: [''] })"
        class="float-right"
        ><i class="lni lni-plus mr-2"></i>Add broker</a
      >
      <label>brokers</label>
      <div
        class="`card mb-3"
        v-for="(broker, i) in content.brokers"
        :key="`broker-${i}`"
      >
        <div
          :class="`card-body uk-card-${i % 2 == 0 ? 'secondary' : 'primary'}`"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <a href="#" class="float-right" @click.prevent="content.brokers.splice(i, 1)"
                ><i class="lni lni-trash-can mr-2"></i>Delete</a
              >
            </div>
            <div class="col-md-3">
              <div>
                <input class="mb-2" type="file" ref="broker_logos" />
                <img
                  :src="broker.logo"
                  :alt="broker.title"
                  style="height: 100px"
                />
                <h2 class="mb-0">
                  <input
                    type="text"
                    v-model="broker.title"
                    placeholder="Title"
                    class="form-control mt-3"
                  />
                </h2>
                <input
                  type="text"
                  placeholder="Link"
                  v-model="broker.link"
                  class="form-control mt-4"
                />
              </div>
            </div>
            <div class="col-md-9">
              <ul>
                <li
                  class="text-bold"
                  v-for="(feature, j) in broker.features"
                  :key="`feature-${i}-${j}`"
                >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Feature"
                      v-model="broker.features[j]"
                    />
                    <a
                      href="#"
                      @click.prevent="broker.features.splice(j, 1)"
                      class="btn btn-outline-secondary border"
                      type="submit"
                    >
                      <i class="lni lni-trash-can"></i>
                    </a>
                  </div>
                </li>
              </ul>
              <a href="#" @click.prevent="broker.features.push('')" class="float-right"><i class="lni lni-plus mr-2"></i>Add Feature</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      content: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/pages/top-brokers").then((response) => {
        this.content = response.data.content;
        this.loading = false;
      });
    },

    update() {
      let data = new FormData
      data.append('intro[title]', this.content.intro.title)
      data.append('intro[content]', this.content.intro.content)
      
      let logos = this.$refs.broker_logos
      
      data.brokers = this.content.brokers.forEach((broker, i) => {
        data.append(`brokers[${i}][title]`, broker.title)
        data.append(`brokers[${i}][link]`, broker.link)
        data.append(`brokers[${i}][logo]`, broker.logo)

        broker.features.forEach((feature, j) => {
          data.append(`brokers[${i}][features][${j}]`, feature)
        })

        if(logos[i].files[0]) {
          data.append(`brokers[${i}][new_logo]`, logos[i].files[0])
        }
      })

      this.loading = true
      this.$axios.post('/api/v1/admin/pages/top-brokers', data).then(() => {
        this.fetch()
      })
    }
  },
};
</script>
